
import { ref } from 'vue'
import { Component, Vue, Watch, Prop, Ref } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
@Component({
  computed: {
    ...mapState('StepperDocumentModule', ['SteppersDocumentState']),
    ...mapGetters('MaintenanceModule', ['CompanyActivities', 'getCompanyMaintenance']),
  },
})
export default class DialogTemplatesComponent extends Vue {
  @Prop({ required: true }) readonly showDialog!: string
  @Prop({ required: false }) readonly title!: string
  @Prop({ required: false }) readonly action!: string
  @Prop({ required: false, default: 'Cancelar' }) readonly cancelButton!: string
  @Prop({ required: false }) documentData!: any
  @Prop({ required: false }) staffData!: any
  @Prop({ required: false }) staffOptions!: any

  private dialog = false
  public options = ref([])
  public responsibleEmail = ''
  private alertDescription = ''
  private characterCount = 0
  private hasError = false
  public dataAlert = {
    description: '',
    evidenceId: 0,
    personalGuid: '',
  }
  private countdown() {
    this.characterCount = this.alertDescription.length
    this.hasError = this.characterCount > 479
  }

  @Watch('showDialog')
  show(val: boolean): void {
    if (val) {
      this.dialog = true
    }
  }

  @Watch('dialog')
  dialogClose(): void {
    if (this.dialog === false) {
      this.$emit('close')
      this.cleanModal()
    }
  }

  private matchResponsible() {
    this.staffData.forEach((responsible: any) => {
      if (responsible.email == this.responsibleEmail) {
        this.dataAlert.personalGuid = responsible.guid
      }
    })
  }

  private sendNotification() {
    this.dataAlert.evidenceId = this.documentData.activityId
    this.dataAlert.description = this.alertDescription
    this.dialog = false
    this.$store
      .dispatch('StepperDocumentModule/sendNotificationAlert', this.dataAlert)
    this.cleanModal()
  }

  @Watch('documentData')
  onDocumentDataChanged(): void {
    if (this.documentData.responsible?.email) {
      this.responsibleEmail = this.documentData.responsible.email
      this.matchResponsible()
    }
  }
  private cleanModal() {
    this.dataAlert = {
      description: '',
      evidenceId: 0,
      personalGuid: '',
    }
    this.responsibleEmail = ''
    this.alertDescription = ''
  }
}
