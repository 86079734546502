
import { Component, Vue } from 'vue-property-decorator'
import { ActivityInterface } from '@/store/types/Activities/ActivitiesGeneral'
import { mapGetters, mapState } from 'vuex'
import OverlayBackground from '@/components/OverlayBackground/Index.vue'
import SendAlertModal from '@/views/Shared/Documents/SendAlertModal.vue'
import Unauthorized from '@/components/Unauthorized/index.vue';
import router from '@/router'
@Component({
  components: { OverlayBackground, SendAlertModal, Unauthorized},
  computed: {
    ...mapState('StepperDocumentModule', ['SteppersDocumentState']),
    ...mapGetters('MaintenanceModule', ['CompanyActivities', 'getCompanyMaintenance']),
    UnauthorizedRole() {
      const role = this.$store.state.AuthModule.AuthState.role;
      return !role.some((role: any) => role.includes("Cliente") || role.includes("Documents") || role.includes("Implementador"));
    },
  },
})
export default class InputComponent extends Vue {
  private loading = false
  private CompanyActivities: any
  private selected = false
  private people: ActivityInterface[] = []
  private role = this.$store.state.AuthModule.AuthState.role
  private expirationDate = ''
  private dateSort = 'desc'
  private activitiesExpired = []
  private showTable = false
  private redDate = 'red-date'
  private yellowDate = 'yellow-date'
  private notComplies = 'not-complies-icon'
  private Business = this.$store.state.BusinessModule.BusinessState.businessClient
  private documentsFiltered = []
  private textFind = ''
  private enabled = false
  private checkedStatus = []
  private statusList =
    this.$store.state.StepperDocumentModule.SteppersDocumentState.activitys.evidences

  private selectedComplies = false
  private selectedNoComplies = false
  private selectedToExpired = false

  private ascendingName = true
  private ascendingResponsible = false
  private ascendingVersion = false
  private ascendingStatus = false
  private ascendingDate = false

  public URL_HELP = process.env.VUE_APP_MODULE_REDIRECT
  public showAlertModal = false
  public documentData = {}
  private staffData = []
  private staffOptions = []
  private unauthorized = false
  private ADMIN_ROLES = process.env.VUE_APP_PRIVILEGED_ROLES?.split(",") || [];
  private closeModal() {
    this.showAlertModal = false
  }
  private openAlertModal(document: any) {
    this.showAlertModal = true
    this.documentData = document
  }
  mounted() {
    this.$mixpanel.track('Evidencia - Documentos')
    this.getByCompanyId()
  }
  public goToMixpanel() {
    this.$mixpanel.track('ver articulo de Documentos')
  }
  private getByCompanyId() {
    this.loading = true
    this.showTable = false
    this.$store.dispatch('StepperDocumentModule/getByCompanyId').then((response) => {
      this.activitiesExpired = response.data.data.evidences
      this.documentsFiltered = response.data.data.evidences
      this.staffData = response.data.data.staff
      this.staffOptions = response.data.data.staff.map((item: any, index: any) => ({
        label: item.fullName,
        value: item.email,
      }))
      this.showTable = true
      this.loading = false
      this.unauthorized = false;
    })
    .catch((error) => {
      if (error.response.status === 403) {
        this.unauthorized = true;
      }
      if (error.response.status === 401) {
        router.push('/')
        location.reload()
      }
      this.loading = false;
    });
  }

  private filterStatus(filterBy: string) {
    this.checkStatus(filterBy)

    if (!this.selectedComplies && !this.selectedNoComplies && !this.selectedToExpired) {
      return (this.documentsFiltered = this.activitiesExpired)
    } else {
      if (filterBy === 'to_expired') {
        this.documentsFiltered = this.activitiesExpired.filter((item: any) => {
          return item.toExpired
        })
      } else {
        this.documentsFiltered = this.activitiesExpired.filter((item: any) => {
          return item.status === filterBy
        })
      }
    }
  }
  private checkStatus(filter: string) {
    if (filter === 'no_complies') {
      this.selectedNoComplies = !this.selectedNoComplies
      this.selectedComplies = false
      this.selectedToExpired = false
    } else if (filter === 'complies') {
      this.selectedComplies = !this.selectedComplies
      this.selectedNoComplies = false
      this.selectedToExpired = false
    } else {
      this.selectedToExpired = !this.selectedToExpired
      this.selectedComplies = false
      this.selectedNoComplies = false
    }
  }

  private findDocument() {
    if (this.textFind.length > 2 || this.textFind.length === 0) {
      this.$mixpanel.track('Filtro Evidencias', { Buscar: this.textFind })
      this.documentsFiltered = this.activitiesExpired.filter((item: any) => {
        return item.activityName.toLowerCase().includes(this.textFind.toLowerCase())
      })
    } else {
      this.documentsFiltered = this.activitiesExpired
    }
  }

  private diffDays = (value: any) => {
    const dateNow = new Date()
    const expireDate = new Date(value)
    const diffTime = expireDate.valueOf() - dateNow.valueOf()
    const diffDays = diffTime / (1000 * 60 * 60 * 24)
    return diffDays
  }

  private goTo(value: ActivityInterface) {
    // Nuevo Dispatch Get Detail Activity

    if (value.expirationDate === null) {
      return
    }
    this.$mixpanel.track('Actividad seleccionada - Mantencion', {
      Actividad: value.activityId,
    })
    if (!this.ADMIN_ROLES.some((adminRole: any) => this.role.includes(adminRole))){
    this.$store.commit('ActivitiesModule/SET_TO_EXPIRED', value.toExpired)
      this.$router
        .push({
          name: 'maintenanceDetail',
          params: { activity_id: value.activityId ? value.activityId?.toString() : '' },
        })
        .catch((error) => {
          if (error.name != 'NavigationDuplicated') return
        })
    } else {
      this.$router
        .push({
          name: 'maintenanceDetailClient',
          params: { activity_id: value.activityId ? value.activityId?.toString() : '' },
        })
        .catch((error) => {
          if (error.name != 'NavigationDuplicated') return
        })
    }
  }

  private sortByDate() {
    // this.$mixpanel.track("Ordenar por Fechas");
    this.dateSort = this.dateSort === 'desc' ? 'asc' : 'desc'
    this.getMaintenanceActivity()
  }

  private getMaintenanceActivity() {
    this.loading = true
    this.showTable = false
    this.$store
      .dispatch('MaintenanceModule/getCompanyMaintenance', this.dateSort)
      .then((response) => {
        this.loading = false
        this.activitiesExpired = response
        this.showTable = true
      })
  }

  private ascendingIcon(item: string) {
    if (item === 'name') {
      return this.ascendingName ? 'arrow-asc' : 'arrow-desc'
    } else if (item === 'responsible') {
      return this.ascendingResponsible ? 'arrow-asc' : 'arrow-desc'
    } else if (item === 'version') {
      return this.ascendingVersion ? 'arrow-asc' : 'arrow-desc'
    } else if (item === 'status') {
      return this.ascendingStatus ? 'arrow-asc' : 'arrow-desc'
    } else if (item === 'date') {
      return this.ascendingDate ? 'arrow-asc' : 'arrow-desc'
    }
  }

  private compare(propertyA: any, propertyB: any, isAscend: boolean) {
    return (propertyA < propertyB ? -1 : 1) * (isAscend ? 1 : -1)
  }

  private sortData(item: string) {
    let ascendingValue: boolean
    switch (item) {
      case 'name':
        ascendingValue = !this.ascendingName
        this.documentsFiltered.sort((a: any, b: any) =>
          this.compare(parseInt(a.activityName, 10), parseInt(b.activityName, 10), ascendingValue)
        )
        this.ascendingName = ascendingValue
        break
      case 'responsible':
        ascendingValue = !this.ascendingResponsible
        this.documentsFiltered.sort((a: any, b: any) =>
          this.compare(
            (a.responsible?.fullName || '').toLowerCase(),
            (b.responsible?.fullName || '').toLowerCase(),
            ascendingValue
          )
        )
        this.ascendingResponsible = ascendingValue
        break
      case 'version':
        ascendingValue = !this.ascendingVersion
        this.documentsFiltered.sort((a: any, b: any) =>
          this.compare(a.version, b.version, ascendingValue)
        )
        this.ascendingVersion = ascendingValue
        break
      case 'status':
        ascendingValue = !this.ascendingStatus
        this.documentsFiltered.sort((a: any, b: any) =>
          this.compare(a.status, b.status, ascendingValue)
        )
        this.ascendingStatus = ascendingValue
        break
      case 'date':
        ascendingValue = !this.ascendingDate
        this.documentsFiltered.sort((a: any, b: any) =>
          this.compare(a.expirationDate, b.expirationDate, ascendingValue)
        )
        this.ascendingDate = ascendingValue
        break
    }
  }
}
